import { initContract } from '@ts-rest/core';
import { z } from 'zod';
import { createStateSchema, offsetPaginationSchema } from '@innerwell/dtos';
import { type Prisma } from '@innerwell/prisma-app-generated';

// https://stackoverflow.com/questions/68366105/get-full-type-on-prisma-client
type DefaultReturnType = Prisma.StateGetPayload<true>;

const c = initContract();
export const states = c.router(
   {
      createState: {
         method: 'POST',
         path: '/states',
         body: createStateSchema,
         responses: {
            200: c.type<Partial<Prisma.StateCreateInput>>(),
         },
      },
      updateState: {
         method: 'PATCH',
         path: '/states/:id',
         pathParams: z.object({
            id: z.string(),
         }),
         body: createStateSchema,
         responses: {
            200: c.type<Partial<Prisma.StateUpdateInput>>(),
         },
      },
      getStateById: {
         method: 'GET',
         path: '/states/:id',
         pathParams: z.object({
            id: z.string(),
         }),
         responses: {
            200: c.type<DefaultReturnType>(),
         },
      },
      getStateByAbbr: {
         method: 'GET',
         path: '/states/abbr/:shortName',
         pathParams: z.object({
            shortName: z.string(),
         }),
         responses: {
            200: c.type<DefaultReturnType>(),
         },
      },
      getStates: {
         method: 'GET',
         path: '/states',
         query: offsetPaginationSchema,
         responses: {
            200: c.type<DefaultReturnType[]>(),
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
