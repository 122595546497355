import {
   type AdminMagentoProductResponseDto,
   MagentoProductTypeEnum,
} from '@innerwell/dtos';
import { initContract } from '@ts-rest/core';
import { z } from 'zod';

const c = initContract();

export const products = c.router(
   {
      getAllProducts: {
         method: 'GET',
         path: '/products',
         query: z.object({
            excludeTypeIds: z
               .array(z.nativeEnum(MagentoProductTypeEnum))
               .optional(),
            // will do a LIKE '%{text}%' search
            text: z.string().optional(),
            page: z.number().int().optional(),
            pageSize: z.number().int().optional(),
         }),
         responses: {
            200: c.type<AdminMagentoProductResponseDto>(),
         },
      },
   },
   { strictStatusCodes: true },
);
