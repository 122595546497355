import { initContract } from '@ts-rest/core';
import { createServiceSchema, type ServiceResponseDto } from '@innerwell/dtos';
import { z } from 'zod';

const c = initContract();
export const services = c.router(
   {
      createService: {
         method: 'POST',
         path: '/services',
         body: createServiceSchema,
         responses: {
            200: c.type<ServiceResponseDto>(),
         },
      },
      getServices: {
         method: 'GET',
         path: '/services',
         responses: {
            200: c.type<ServiceResponseDto[]>(),
         },
      },
      getServiceBySlug: {
         method: 'GET',
         path: '/services/:slug',
         pathParams: z.object({
            slug: z.string(),
         }),
         responses: {
            200: c.type<ServiceResponseDto>(),
         },
      },
      updateService: {
         method: 'PATCH',
         path: '/services/:id',
         pathParams: z.object({
            id: z.string(),
         }),
         body: createServiceSchema,
         responses: {
            200: c.type<ServiceResponseDto>(),
         },
      },
      deleteService: {
         method: 'DELETE',
         path: '/services/:id',
         pathParams: z.object({
            id: z.string(),
         }),
         responses: {
            200: c.type<boolean>(),
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
