import { initContract } from '@ts-rest/core';
import {
   cognitoLoginSchema,
   newPasswordSchema,
   verifySchema,
} from '@innerwell/dtos';
import { z } from 'zod';

const c = initContract();
export const authenticate = c.router(
   {
      login: {
         method: 'POST',
         path: '/auth/login',
         body: cognitoLoginSchema,
         responses: {
            200: z.undefined(),
            400: z.string(),
         },
      },

      verify: {
         method: 'POST',
         path: '/auth/verify',
         body: verifySchema,
         responses: {
            200: z.undefined(),
         },
      },

      setNewPassword: {
         method: 'POST',
         path: '/auth/set-new-password',
         body: newPasswordSchema,
         responses: {
            200: z.undefined(),
         },
      },

      refreshToken: {
         method: 'POST',
         path: '/auth/refresh',
         body: c.noBody(),
         responses: {
            200: z.undefined(),
            403: z.string().optional(),
         },
      },

      logout: {
         method: 'POST',
         path: '/auth/logout',
         body: c.noBody(),
         responses: {
            200: z.undefined(),
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
