import { initContract } from '@ts-rest/core';
import { z } from 'zod';
import {
   getPatientsSchema,
   uuidSchema,
   type WelkinPageableResponse,
   type WelkinPatientResponseDto,
   type WelkinUploadedFiles,
   welkinWebhookRequestDtoSchema,
} from '@innerwell/dtos';

const c = initContract();
export const welkin = c.router(
   {
      welkinWebhook: {
         method: 'POST',
         path: '/welkin/webhook',
         body: welkinWebhookRequestDtoSchema,
         responses: {
            200: z.void(),
         },
      },
      getPatients: {
         method: 'GET',
         path: '/welkin/patients',
         query: getPatientsSchema,
         responses: {
            200: c.type<WelkinPageableResponse<WelkinPatientResponseDto>>(),
         },
      },
      getPatient: {
         method: 'GET',
         path: '/welkin/patients/:id',
         pathParams: z.object({
            id: uuidSchema,
         }),
         responses: {
            200: c.type<WelkinPatientResponseDto>(),
         },
      },
      uploadFiles: {
         method: 'POST',
         path: '/welkin/:patientId/upload-files',
         contentType: 'multipart/form-data',
         pathParams: z.object({
            patientId: uuidSchema,
         }),
         body: c.noBody(),
         responses: {
            200: c.type<WelkinUploadedFiles>(),
         },
      },
      downloadFile: {
         method: 'GET',
         path: '/welkin/:patientId/download-file/:documentId/:fileId',
         pathParams: z.object({
            patientId: uuidSchema,
            documentId: uuidSchema,
            fileId: uuidSchema,
         }),
         responses: {
            200: z.void(),
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
