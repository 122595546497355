import { initContract } from '@ts-rest/core';
import {
   createInsuranceSchema,
   type InsuranceResponseDto,
   updateInsuranceCliniciansSchema,
   updateInsuranceEncountersSchema,
} from '@innerwell/dtos';
import { z } from 'zod';

const c = initContract();
export const insurance = c.router(
   {
      createInsurance: {
         method: 'POST',
         path: '/insurance',
         body: createInsuranceSchema,
         responses: {
            200: c.type<InsuranceResponseDto>(),
         },
      },

      updateInsurance: {
         method: 'PATCH',
         path: '/insurance/:id',
         pathParams: z.object({
            id: z.string(),
         }),
         body: createInsuranceSchema,
         responses: {
            200: c.type<InsuranceResponseDto>(),
         },
      },

      deleteInsurance: {
         method: 'DELETE',
         path: '/insurance/:id',
         pathParams: z.object({
            id: z.string(),
         }),
         body: c.type<unknown>(),
         responses: {
            200: c.type<Partial<InsuranceResponseDto>>(),
         },
      },

      getInsurance: {
         method: 'GET',
         path: '/insurance/:id',
         pathParams: z.object({
            id: z.string(),
         }),
         responses: {
            200: c.type<InsuranceResponseDto>(),
         },
      },

      getInsurances: {
         method: 'GET',
         path: '/insurance',
         responses: {
            200: c.type<InsuranceResponseDto[]>(),
         },
      },

      updateEncounters: {
         method: 'PUT',
         path: '/insurance/:id/encounters',
         pathParams: z.object({
            id: z.string(),
         }),
         body: updateInsuranceEncountersSchema,
         responses: {
            200: z.void(),
         },
      },

      updateClinicians: {
         method: 'PUT',
         path: '/insurance/:id/clinicians',
         pathParams: z.object({
            id: z.string(),
         }),
         body: updateInsuranceCliniciansSchema,
         responses: {
            200: z.void(),
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
