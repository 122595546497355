import { getServerPublicRuntimeConfig } from './server-public-runtime-config';
import { namespaceKey } from '../consts/keys';

export const getClientPublicRuntimeConfig = () => {
   if (typeof window === 'undefined') {
      return getServerPublicRuntimeConfig();
   }

   return window[namespaceKey].runtimeConfig;
};
