import { initContract } from '@ts-rest/core';
import { z } from 'zod';
import { type PrecisionWebhookTypeUnion } from '@innerwell/api-precision-types';

const c = initContract();
export const precision = c.router(
   {
      create: {
         method: 'POST',
         path: '/precision',
         body: c.type<PrecisionWebhookTypeUnion>(),
         responses: {
            200: z.string(),
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
