import { initContract } from '@ts-rest/core';
import {
   type CreateFromOrderResult,
   createOrderAndTransactionParamsDtoSchema,
   createTransactionSchema,
   type FailedPaymentDto,
   type InnerwellOrderDetails,
   intExtendedField,
   type NmiCustomerProcessed,
   type NmiSubscriptionProcessed,
   type NmiTransactionProcessed,
   type NmiTransactionProcessedDto,
   refundTransactionSchema,
   retryPaymentAdminSchema,
   type RetryPaymentResponse,
   updateSubscriptionSchema,
   updateTransactionParamsSchema,
   uuidSchema,
} from '@innerwell/dtos';
import { z } from 'zod';

const c = initContract();
export const payments = c.router(
   {
      getTransactions: {
         method: 'GET',
         path: '/patient-payments/:patientId/transactions',
         pathParams: z.object({
            patientId: uuidSchema,
         }),
         responses: {
            200: c.type<NmiTransactionProcessed[]>(),
         },
      },

      createTransaction: {
         method: 'POST',
         path: '/patient-payments/:patientId/transactions',
         pathParams: z.object({
            patientId: uuidSchema,
         }),
         body: createTransactionSchema,
         responses: {
            200: c.type<NmiTransactionProcessedDto>(),
         },
      },

      getTransactionById: {
         method: 'GET',
         path: '/patient-payments/transactions/:transactionId',
         pathParams: z.object({
            transactionId: z.string(),
         }),
         responses: {
            200: c.type<NmiTransactionProcessed>(),
         },
      },

      voidTransaction: {
         method: 'POST',
         path: '/patient-payments/transactions/:transactionId/void',
         pathParams: z.object({
            transactionId: z.string(),
         }),
         body: c.noBody(),
         responses: {
            200: c.type<NmiTransactionProcessedDto>(),
         },
      },

      refundTransaction: {
         method: 'POST',
         path: '/patient-payments/transactions/:transactionId/refund',
         pathParams: z.object({
            transactionId: z.string(),
         }),
         body: refundTransactionSchema,
         responses: {
            200: c.type<NmiTransactionProcessedDto>(),
         },
      },

      getSubscriptions: {
         method: 'GET',
         path: '/patient-payments/:patientId/subscriptions',
         pathParams: z.object({
            patientId: uuidSchema,
         }),
         responses: {
            200: c.type<NmiSubscriptionProcessed[]>(),
         },
      },

      updateSubscription: {
         method: 'PATCH',
         path: '/patient-payments/subscriptions/:subscriptionId',
         pathParams: z.object({
            subscriptionId: z.string(),
         }),
         body: updateSubscriptionSchema,
         responses: {
            200: c.noBody(),
         },
      },

      removeSubscription: {
         method: 'DELETE',
         path: '/patient-payments/subscriptions/:subscriptionId',
         pathParams: z.object({
            subscriptionId: z.string(),
         }),
         body: c.noBody(),
         responses: {
            200: c.noBody(),
         },
      },

      getSubscriptionById: {
         method: 'GET',
         path: '/patient-payments/subscriptions/:subscriptionId',
         pathParams: z.object({
            subscriptionId: z.string(),
         }),
         responses: {
            200: c.type<NmiSubscriptionProcessed>(),
         },
      },

      getOrder: {
         method: 'GET',
         path: '/patient-payments/orders/:orderId',
         pathParams: z.object({
            orderId: intExtendedField,
         }),
         responses: {
            200: c.type<InnerwellOrderDetails>(),
         },
      },

      //returns customer vault default billing (ver:1)
      getCustomerVaultInfo: {
         method: 'GET',
         path: '/patient-payments/:patientId/customer-vault',
         pathParams: z.object({
            patientId: uuidSchema,
         }),
         responses: {
            200: c.type<NmiCustomerProcessed | null>(),
         },
      },

      syncWelkinPaymentCdt: {
         method: 'POST',
         path: '/patient-payments/:patientId/sync-welkin-payment-cdt/:cdtId',
         pathParams: z.object({
            patientId: uuidSchema,
            cdtId: uuidSchema,
         }),
         body: c.noBody(),
         responses: {
            200: c.noBody(),
         },
      },

      failedPayment: {
         method: 'GET',
         path: '/patient-payments/failed/:patientId/:id',
         pathParams: z.object({
            patientId: uuidSchema,
            id: uuidSchema,
         }),
         responses: {
            200: c.type<FailedPaymentDto>(),
         },
         metadata: {
            dontCheckCredentials: true,
         },
      },

      getFailedPayments: {
         method: 'GET',
         path: '/patient-payments/failed/:patientId',
         pathParams: z.object({
            patientId: uuidSchema,
         }),
         responses: {
            200: c.type<FailedPaymentDto[]>(),
         },
      },

      retryTransaction: {
         method: 'POST',
         path: '/patient-payments/:patientId/retry-transaction',
         pathParams: z.object({
            patientId: uuidSchema,
         }),
         body: retryPaymentAdminSchema,
         responses: {
            200: c.type<RetryPaymentResponse>(),
         },
      },

      updateTransaction: {
         method: 'PATCH',
         path: '/patient-payments/transactions/:transactionId',
         pathParams: z.object({
            transactionId: z.string(),
         }),
         body: updateTransactionParamsSchema,
         responses: {
            200: c.noBody(),
         },
      },

      createOrderAndTransaction: {
         method: 'POST',
         path: '/patient-payments/:patientId/create-order-and-transaction',
         pathParams: z.object({
            patientId: z.string(),
         }),
         body: createOrderAndTransactionParamsDtoSchema,
         responses: {
            200: c.type<CreateFromOrderResult | null>(),
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
