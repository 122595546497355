import { initContract } from '@ts-rest/core';
import {
   createTreatmentMethodSchema,
   type TreatmentMethodResponseDto,
} from '@innerwell/dtos';
import { z } from 'zod';

const c = initContract();
export const treatmentMethods = c.router(
   {
      createTreatmentMethod: {
         method: 'POST',
         path: '/treatment-methods',
         body: createTreatmentMethodSchema,
         responses: {
            200: c.type<TreatmentMethodResponseDto>(),
         },
      },
      getTreatmentMethods: {
         method: 'GET',
         path: '/treatment-methods',
         responses: {
            200: c.type<TreatmentMethodResponseDto[]>(),
         },
      },
      getTreatmentMethodBySlug: {
         method: 'GET',
         path: '/treatment-methods/:slug',
         pathParams: z.object({
            slug: z.string(),
         }),
         responses: {
            200: c.type<TreatmentMethodResponseDto>(),
         },
      },
      updateTreatmentMethod: {
         method: 'PATCH',
         path: '/treatment-methods/:id',
         pathParams: z.object({
            id: z.string(),
         }),
         body: createTreatmentMethodSchema,
         responses: {
            200: c.type<TreatmentMethodResponseDto>(),
         },
      },
      deleteTreatmentMethod: {
         method: 'DELETE',
         path: '/treatment-methods/:id',
         pathParams: z.object({
            id: z.string(),
         }),
         responses: {
            200: c.type<boolean>(),
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
