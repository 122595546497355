import { initContract } from '@ts-rest/core';
import {
   type AdminPatientEncounterResponseDto,
   type PatientCdtsItem,
   type PatientCdtsResponse,
   type PatientShippingAddressDto,
   patientShippingAddressSchema,
   uuidSchema,
   WelkinCdtEnum,
   type WelkinPatientResponseDto,
} from '@innerwell/dtos';
import { z } from 'zod';

const c = initContract();
export const patients = c.router(
   {
      changeEmail: {
         method: 'POST',
         path: '/patients/:patientId/change-email',
         pathParams: z.object({
            patientId: uuidSchema,
         }),
         body: z.object({
            email: z.string(),
         }),
         responses: {
            200: z.void(),
         },
      },

      getShippingAddress: {
         method: 'GET',
         path: '/patients/:patientId/shipping-address',
         pathParams: z.object({
            patientId: uuidSchema,
         }),
         responses: {
            200: c.type<PatientShippingAddressDto>(),
         },
      },

      changeShippingAddress: {
         method: 'PUT',
         path: '/patients/:patientId/shipping-address',
         pathParams: z.object({
            patientId: uuidSchema,
         }),
         body: patientShippingAddressSchema,
         responses: {
            200: c.type<WelkinPatientResponseDto>(),
         },
      },

      getPatientCdt: {
         method: 'GET',
         path: '/patients/:patientId/cdts/:cdtName/:cdtId',
         pathParams: z.object({
            patientId: uuidSchema,
            cdtName: z.nativeEnum(WelkinCdtEnum),
            cdtId: uuidSchema,
         }),
         responses: {
            200: c.type<PatientCdtsItem<unknown>>(),
         },
      },

      getPatientCdts: {
         method: 'GET',
         path: '/patients/:patientId/cdts/:cdtName',
         pathParams: z.object({
            patientId: uuidSchema,
            cdtName: z.nativeEnum(WelkinCdtEnum),
         }),
         responses: {
            200: c.type<PatientCdtsResponse<unknown>>(),
         },
      },

      getPatientEncounters: {
         method: 'GET',
         path: '/patients/:patientId/welkin-encounters',
         pathParams: z.object({
            patientId: uuidSchema,
         }),
         responses: {
            200: c.type<AdminPatientEncounterResponseDto[]>(),
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
