import { initContract } from '@ts-rest/core';
import { z } from 'zod';
import {
   paginationSchema,
   updateServiceProviderLocationSchema,
   type ServiceProviderLocationsResponse,
} from '@innerwell/dtos';

const c = initContract();
export const serviceProviderLocations = c.router(
   {
      createServiceProviderLocation: {
         method: 'PUT',
         path: '/service-provider-locations',
         body: updateServiceProviderLocationSchema,
         responses: {
            200: z.void(),
         },
      },

      getServiceProviderLocations: {
         method: 'GET',
         path: '/service-provider-locations',
         query: paginationSchema,
         responses: {
            200: c.type<ServiceProviderLocationsResponse | []>(),
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
