import { initContract } from '@ts-rest/core';
import { createEncounterSchema, offsetPaginationSchema } from '@innerwell/dtos';
import {
   type EncounterType,
   type Prisma,
} from '@innerwell/prisma-app-generated';
import { z } from 'zod';

const c = initContract();
export const encounters = c.router(
   {
      createEncounter: {
         method: 'POST',
         path: '/encounters',
         body: createEncounterSchema,
         responses: {
            200: c.type<Partial<Prisma.EncounterTypeCreateInput>>(),
         },
      },

      updateEncounter: {
         method: 'PATCH',
         path: '/encounters/:id',
         pathParams: z.object({
            id: z.string(),
         }),
         body: createEncounterSchema.partial(),
         responses: {
            200: c.type<Partial<Prisma.EncounterTypeCreateInput>>(),
         },
      },

      getEncounter: {
         method: 'GET',
         path: '/encounters/:id',
         pathParams: z.object({
            id: z.string(),
         }),
         responses: {
            200: c.type<EncounterType>(),
         },
      },

      getEncounters: {
         method: 'GET',
         path: '/encounters',
         pathParams: offsetPaginationSchema,
         responses: {
            200: c.type<EncounterType[]>(),
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
