import { initContract } from '@ts-rest/core';
import {
   type FormsortFormDataDto,
   formsortFormDataFilterSchema,
} from '@innerwell/dtos';

const c = initContract();
export const formsort = c.router(
   {
      getFormData: {
         method: 'GET',
         path: '/formsort/formData',
         query: formsortFormDataFilterSchema,
         responses: {
            200: c.type<FormsortFormDataDto>(),
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
